<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <UserForm
      :user="newUser"
      :errors="userValidationErrors"
      :disabled="$store.getters.loading['post:api/client-portal-user']"
      @clear:errors="CLEAR_USER_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import UserForm from '@/components/forms/UserForm';

export default {
  name: 'CreateUser',

  components: { UserForm },

  computed: {
    ...mapState('users', ['newUser', 'userValidationErrors', 'userFilterParams']),
  },

  created() {
    this.SET_USER_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('users', ['storeUser']),
    ...mapMutations('users', ['SET_USER_VALIDATION_ERRORS', 'CLEAR_USER_VALIDATION_ERRORS']),

    goBack() {
      this.$router.push({ name: 'users', query: this.userFilterParams });
    },

    onSave(user) {
      this.storeUser(user).then(() => {
        this.goBack();
      });
    },
  },
};
</script>
